import React from 'react'
import Header from '../components/Header.jsx';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export const Posts = () => {
    return (
        <h2 className="about-me">
            <Header />
            <Container maxWidth="lg">
                <Box sx={{ bgcolor: '#fff', height: '100vh' }}>
                    <h2>Posts </h2>
                    <Box sx={{ bgcolor: '#fff' }}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={4}>
                                <PostItem 
                                    postTitle={"NATS"} 
                                    postDate={"November 09, 2022"} 
                                    postDesc={"a solid alternative to Kafka and RabbitMQ"}
                                    postPath={"../posts/nats"} />
                            </Grid> */}
                            {/* <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </h2>
    )
}

export default Posts
