import React from 'react'
import Header from '../components/Header.jsx';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ProjectItem from '../components/ProjectItem.jsx';
import Grid from '@mui/material/Grid';


export const Projects = () => {
    return (
        <h2 className="about-me">
            <Header />
            <Container maxWidth="lg">
                <Box sx={{ bgcolor: '#fff', height: '100vh' }}>
                    <h2>Projects</h2>
                    <Box sx={{ bgcolor: '#fff' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <ProjectItem />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <ProjectItem />
                            </Grid>
                            <Grid item xs={4}>
                                <ProjectItem />
                            </Grid>
                            <Grid item xs={4}>
                                <ProjectItem />
                            </Grid>
                            <Grid item xs={4}>
                                <ProjectItem />
                            </Grid>
                            <Grid item xs={4}>
                                <ProjectItem />
                            </Grid> */}
                        </Grid>

                    </Box>
                    <div>
                    </div>
                </Box>
            </Container>
        </h2>
    )
}

export default Projects
