import React from 'react'
import { useState } from "react"
import "../styles/Header.css"
import { Link } from "react-router-dom";

function Header() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <nav className="navigation">
    
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
       
       <div className="hamburger-line"/>
       <div className="hamburger-line"/>
       <div className="hamburger-line"/>

      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
          <Link className="home-headingw" to="/">Kaan 🧑🏻‍💻</Link>
          </li>
          <li>
          <Link className="home-headingw" to="/about">About me</Link>
          </li>
          <li>
          <Link className="home-headingw" to="/posts">Posts</Link>
          </li>
          {/* <li>
          <Link className="home-headingw" to="/projects">Projects</Link>
          </li> */}
          <li>
          <a className="home-headingw"  href="https://github.com/knhakan">Github 🔗</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header