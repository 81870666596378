import React from 'react'
import '../styles/Aboutme.css'
import Header from '../components/Header.jsx';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export const Aboutme = () => {
  return (
    <h2 className="about-me">
      <Header />
      <Container maxWidth="lg" className="about-me-container">
        <Box sx={{ bgcolor: '#fff', height: '100vh' }}>
          <h2>About me</h2>
          <Box sx={{ width: 1 / 4, bgcolor: '#fff' }} className="about-me-text"> 
            <div>
              I am a software developer working in Berlin, I am partly a digital nomad. I am passionate about building software that is easy to use and maintain.
            </div>
            <div>My intention here is to share what I know, learn and want to remind myself in the future.</div>

          </Box>
          <h3>My skills</h3>
          <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20 }}></div>
          <Box sx={{ width: 3 / 4, bgcolor: '#fff' }} className="about-me-text"> 
            <li>
            <b>Languages</b>: Java, Kotlin, Javascript, React, Go, Python 
            </li>
            <li>
            <b>Concepts & Tools</b>: Databases (SQL & NoSQL), Microservices, REST, GraphQL, CI/CD, Docker, Kubernetes, 
            AWS, GCP, Git, Agile, Scrum, Job management systems, push notifications and more.
            </li>
          </Box>
          <h3>Interests</h3>
          <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20 }}></div>
          <Box sx={{ width: 3 / 4, bgcolor: '#fff' }} className="about-me-text"> 
            Travelling, playing tennis, hiking and cycling are my biggest passions. I occasionally write about my experiences on my blog.
            I edit videos and publish them on my YouTube channel. 
          </Box>

          <h3>Miscellaneous</h3>
          <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20 }}></div>
          <Box sx={{ width: 3 / 4, bgcolor: '#fff' }} className="about-me-text"> 
          Check out my resume
          </Box>

        </Box>
      </Container>
    </h2>
  )
}

export default Aboutme
