import React from 'react'
import Header from '../components/Header.jsx';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import '../styles/Home.css'
import Grid from '@mui/material/Grid';

export const Home = () => {
    return (
        <h2 >
            <Header />
            <Container maxWidth="lg">
            <h2>Hi, I am Kaan! 👋 </h2>

                <Box sx={{ bgcolor: '#fff', height: '100vh' }} className="home-box">
                    <Box sx={{ width: 1 / 4, bgcolor: '#fff' }} > 
                    <div>Welcome to my digital garden!🪴</div>
                        I'm a software developer who creates open-source projects and writes about code, design, and life. I like tennis, travelling and hiking.
                    </Box>
                    <div>
                        In this blog, I write about my journey as a software developer, my projects, and my thoughts on life. I hope you enjoy reading my posts as much as I enjoy writing them.
                    <Box>
                        <h3>Latest Posts</h3>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={4}>
                                <PostItem 
                                    postTitle={"NATS"} 
                                    postDate={"November 09, 2022"} 
                                    postDesc={"a solid alternative to Kafka and RabbitMQ"}
                                    postPath={"../posts/nats"} />
                            </Grid> */}
                            {/* <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid>
                            <Grid item xs={4}>
                                <PostItem />
                            </Grid> */}
                        </Grid>
                    </Box>
                    <Box>
                        <h3>Popular Tutorials</h3>
                    </Box>
                    <Box>
                        <h3>Side Projects</h3>
                    </Box>
                    </div>
                </Box>
                
            </Container>

        </h2>
    )
}

export default Home
